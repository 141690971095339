.charts-container {
  box-sizing: border-box;
  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 12px;
  right: 12px;
  padding-top: 7px;
  z-index: 3;
  background-color: white;
  box-shadow: 0px 0px 5px 4px rgb(200 200 200);
  overflow-y: scroll;

  .hr {
    height: 1px;
    width: 100%;
    background-color: rgb(200, 200, 200);
  }
}

.tooltip-content-container {
  text-align: center;
  min-width: 100px;

  .mc-container {
    display: flex;
  }

  .mc-number {
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    font-size: 16px;
    width: 58px;
  }

  .mc-units {
    height: 20px;
    line-height: 20px;
  }

  .mc-season {
    font-size: 10px;
    font-style: italic;
    color: #5e5e5e;
  }

  .mc-occurred {
    margin: 3px 0px 6px 0px;
  }

  .wpnd-container {
    display: flex;
    
    .wpnd-number {
      font-family: 'Roboto', 'Helvetica Neue', sans-serif;
      font-weight: bold;
      font-size: 16px;
      text-align: right;
      width: 40px;
    }
    
    .wpnd-units {
      height: 20px;
      line-height: 20px;
      text-align: right;
      width: 90px;
    }
  }
}